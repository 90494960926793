import Icon01 from "@/assets/images/orderIcon01@2x.png";
import Icon02 from "@/assets/images/orderIcon02@2x.png";
import Icon03 from "@/assets/images/orderIcon03@2x.png";
import Icon04 from "@/assets/images/orderIcon04@2x.png";
import Icon05 from "@/assets/images/orderIcon05@2x.png";

// 订单状态字典 0=待支付 1=已支付 2=退款中 3=已退款 4=已发货 5=已收货
export const ORDER_STATE = [
  {
    id: 227,
    dictTypeName: "订单状态",
    dictType: "order_state",
    dictCode: "wait_pay",
    dictName: "待支付",
    dictIcon: "",
    sort: 0,
    remark: "",
    state: true,
    createBy: null,
    createTime: "2023-02-08 22:12:33",
    updateBy: "",
    updateTime: "2023-02-08 22:12:56",
  },
  {
    id: 169,
    dictTypeName: "订单状态",
    dictType: "order_state",
    dictCode: "paid",
    dictName: "已支付",
    dictIcon: "",
    sort: 1,
    remark: "",
    state: true,
    createBy: null,
    createTime: "2022-10-12 12:46:58",
    updateBy: "",
    updateTime: "2022-11-03 13:19:54",
  },
  {
    id: 171,
    dictTypeName: "订单状态",
    dictType: "order_state",
    dictCode: "refunding",
    dictName: "退款中",
    dictIcon: "",
    sort: 2,
    remark: "",
    state: true,
    createBy: null,
    createTime: "2022-10-12 12:47:29",
    updateBy: "",
    updateTime: "2022-11-03 13:19:57",
  },
  {
    id: 172,
    dictTypeName: "订单状态",
    dictType: "order_state",
    dictCode: "refunded",
    dictName: "已退款",
    dictIcon: "",
    sort: 3,
    remark: "",
    state: true,
    createBy: null,
    createTime: "2022-10-12 12:47:55",
    updateBy: "",
    updateTime: "2022-11-03 13:19:59",
  },
  {
    id: 173,
    dictTypeName: "订单状态",
    dictType: "order_state",
    dictCode: "shipped",
    dictName: "已发货",
    dictIcon: "",
    sort: 4,
    remark: "",
    state: true,
    createBy: null,
    createTime: "2022-10-12 12:48:36",
    updateBy: "",
    updateTime: "2022-11-03 13:20:01",
  },
  {
    id: 174,
    dictTypeName: "订单状态",
    dictType: "order_state",
    dictCode: "received",
    dictName: "已收货",
    dictIcon: "",
    sort: 5,
    remark: "",
    state: true,
    createBy: null,
    createTime: "2022-10-12 12:49:04",
    updateBy: "",
    updateTime: "2022-11-03 13:20:03",
  },
];

// 订单前端分组
export const ORDER_STATE_GROUP = {
  UN_PAID: {
    CD: "1",
    NA: "待支付",
    ICON: Icon01,
    DIC_LIST: [ORDER_STATE[0].dictCode],
  },
  UN_RECEIVED: {
    CD: "2",
    NA: "待收货",
    ICON: Icon02,
    DIC_LIST: [ORDER_STATE[1].dictCode, ORDER_STATE[4].dictCode],
  },
  DONE: {
    CD: "3",
    NA: "已完成",
    ICON: Icon03,
    DIC_LIST: [ORDER_STATE[5].dictCode],
  },
  CANCELED: {
    CD: "4",
    NA: "已取消",
    ICON: Icon04,
    DIC_LIST: [ORDER_STATE[2].dictCode, ORDER_STATE[3].dictCode],
  },
  AFTER_SALES: {
    CD: "5",
    NA: "售后",
    ICON: Icon05,
    DIC_LIST: [ORDER_STATE[2].dictCode, ORDER_STATE[3].dictCode],
  },
};

// 售后类型
export const ORDER_AFTER_SALES = [
  {
    dictTypeName: "售后类型",
    dictType: "order_after_sales",
    dictCode: 1,
    dictName: "仅退款",
  },
  {
    dictTypeName: "售后类型",
    dictType: "order_after_sales",
    dictCode: 2,
    dictName: "退货退款",
  },
];

// 快递状态
export const EXPRESS_STATUS = [
  {
    dictTypeName: "快递状态",
    dictType: "express_status",
    dictCode: "0",
    dictName: "快递收件(揽件)",
  },
  {
    dictTypeName: "快递状态",
    dictType: "express_status",
    dictCode: "1",
    dictName: "在途中",
  },
  {
    dictTypeName: "快递状态",
    dictType: "express_status",
    dictCode: "2",
    dictName: "正在派件",
  },
  {
    dictTypeName: "快递状态",
    dictType: "express_status",
    dictCode: "3",
    dictName: "已签收",
  },
  {
    dictTypeName: "快递状态",
    dictType: "express_status",
    dictCode: "4",
    dictName: "派送失败",
  },
  {
    dictTypeName: "快递状态",
    dictType: "express_status",
    dictCode: "5",
    dictName: "疑难件",
  },
  {
    dictTypeName: "快递状态",
    dictType: "express_status",
    dictCode: "6",
    dictName: "退件签收",
  },
];
