export const account = {
  path: "/account",
  component: () => import("../views/account/Index.vue"),
  children: [
    {
      path: "",
      name: "signIn",
      component: () => import("../views/account/SignIn.vue"),
    },
  ],
};
