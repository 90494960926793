export const common = [
  {
    path: "/common/loading",
    name: "loading",
    component: () => import("../views/common/Loading.vue"),
  },
  {
    path: "/common/agreement",
    name: "agreement",
    component: () => import("../views/common/Agreement.vue"),
  },
  {
    path: "/common/privacy",
    name: "privacy",
    component: () => import("../views/common/Privacy.vue"),
  },
  {
    path: "/common/pay/result",
    name: "payResult",
    component: () => import("../views/common/PayResult.vue"),
  },
];
