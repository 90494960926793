<script setup>
import { useRouter } from "vue-router";
import { ref } from "vue";
import RewardModal from "@/components/RewardModal.vue";
import SquareItemModal from "@/components/SquareItemModal.vue";
import { showToast } from "@/utils";
import { ADVICE_STATUS, FETCH_CODE, PRICE_RATE_BACK } from "@/config";
import {
  favoriteAdvice,
  rejectAdvice,
  resolveAdvice,
  rewardAdvice,
} from "@/views/square/api";
import BigNumber from "bignumber.js";
import { useSquareStore } from "@/stores/square";
import { useUserStore } from "@/stores/user";

const emits = defineEmits(["reload"]);

const router = useRouter();
const squareStore = useSquareStore();
const userStore = useUserStore();

const navigateTo = () => {
  router.push("/advice/add");
};
const props = defineProps({
  state: {
    type: String,
    default: ADVICE_STATUS.PASSED.CD,
  },
  list: {
    type: Array,
    default: () => [],
  },
  hasMore: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const visible = ref(false);
const dialogData = ref({});
const showDialog = (item) => {
  item.pictureList = item.pictureUrl ? item.pictureUrl.split(",") : [];
  dialogData.value = item;
  visible.value = true;
};
const pending = ref(false);

const rewardVisible = ref(false);
const rewardData = ref({});
const rewardType = ref("audit");

// 刷新列表
const onReload = () => {
  emits("reload");
};

// 打赏
const onReward = (item, type = "reward") => {
  const current = props.list.find((it) => it.id === item.id);
  if (!current) return;
  if (type === "reward" && current.userId === userStore.info.userId) {
    showToast({ code: 2, msg: "无法给自己打赏！" });
    return;
  }

  rewardType.value = type;
  rewardData.value = item;
  rewardVisible.value = true;
};
// 打赏、审核确认事件
const onRewardConfirm = async (form) => {
  const current = props.list.find((it) => it.id === rewardData.value.id);
  if (!current) return;
  if (rewardType.value === "reward") {
    const params = {
      userProposeId: rewardData.value.id,
      points: BigNumber(form.value).times(PRICE_RATE_BACK).toNumber(),
      payPassword: form.password,
    };
    const res = await rewardAdvice(params);
    if (res.code !== FETCH_CODE.SUCCESS) {
      showToast({ code: FETCH_CODE.ALERT, msg: res.msg });
      return;
    }
    showToast({ code: FETCH_CODE.SUCCESS, msg: "打赏成功" });
    // 刷新列表
    onReload();
    // 刷新排序数据
    squareStore.getRankList();
    // 刷新余额数据
    userStore.getPoints();
    rewardVisible.value = false;
    return;
  }
  if (rewardType.value === "audit") {
    const params = {
      id: rewardData.value.id,
      points: BigNumber(form.value).times(PRICE_RATE_BACK).toNumber(),
      payPassword: form.password,
      // userIds: [current.userId],
    };
    const res = await resolveAdvice(params);
    if (res.code !== FETCH_CODE.SUCCESS) {
      showToast({ code: FETCH_CODE.ALERT, msg: res.msg });
      return;
    }
    showToast({ code: FETCH_CODE.SUCCESS, msg: "操作成功" });
    // 刷新列表
    onReload();
    // 刷新排序数据
    squareStore.getRankList();
    // 刷新余额数据
    userStore.getPoints();
    rewardVisible.value = false;
    return;
  }
  if (rewardType.value === "reject") {
    const params = {
      id: rewardData.value.id,
      remark: form.remark,
    };
    const res = await rejectAdvice(params);
    if (res.code !== FETCH_CODE.SUCCESS) {
      showToast({ code: FETCH_CODE.ALERT, msg: res.msg });
      return;
    }
    showToast({ code: FETCH_CODE.SUCCESS, msg: "操作成功" });
    // 刷新列表
    onReload();
    rewardVisible.value = false;
  }
};
// 点赞
const onLike = async (item) => {
  if (pending.value) return;
  pending.value = true;
  const res = await favoriteAdvice({
    isZan: !item.isMyDianZan,
    userProposeId: item.id,
  });
  if (res.code !== FETCH_CODE.SUCCESS) {
    showToast(res);
    pending.value = false;
    return;
  }
  showToast({
    code: FETCH_CODE.SUCCESS,
    msg: !item.isMyDianZan ? "感谢您的点赞！" : "已取消赞！",
  });
  item.isMyDianZan = !item.isMyDianZan;
  if (item.isMyDianZan) {
    item.dianZanCount += 1;
  } else {
    item.dianZanCount -= 1;
  }
  pending.value = false;
};
// 审核
const onAudit = (item, pass) => {
  //  拒绝reject  同意audit
  rewardType.value = !pass ? "reject" : "audit";
  rewardData.value = item;
  rewardVisible.value = true;
};
</script>

<template>
  <!-- 列表 -->
  <div class="square-list">
    <div
      class="list-item"
      v-for="item in props.list"
      :key="item.key"
      @click="showDialog(item)"
    >
      <div class="list-item-head">
        <div class="head-name">{{ item.title }}</div>
        <div class="head-right">
          <!-- 已通过状态 -->
          <template v-if="item.state === ADVICE_STATUS.PASSED.CD">
            <i class="echeck-ga-icon ec-ga-icon-goldIco"></i>
            <span class="head-right-text">
              {{ userStore.config.moneyName }}奖励
            </span>
            <span class="head-right-light">
              {{ item.showTotalPoints }}
            </span>
          </template>
          <!-- 待审核状态 -->
          <template v-else>
            <span class="head-right-text">{{ item.createTime }}</span>
            <!-- 未通过状态 -->
            <template v-if="item.state === ADVICE_STATUS.INVALID.CD">
              <div class="fail-icon"></div>
            </template>
          </template>
        </div>
      </div>
      <div class="list-item-content multi-hidden">
        {{ item.remark }}
      </div>
      <!--#region 底部 -->
      <div
        class="list-item-bottom"
        v-if="item.state !== ADVICE_STATUS.INVALID.CD"
      >
        <!--#region 已通过 -->
        <div
          class="list-item-bottom-btn"
          v-if="item.state === ADVICE_STATUS.PASSED.CD"
        >
          <i
            class="echeck-ga-icon ec-ga-icon-reward_select"
            @click.stop="onReward(item)"
          />
          <span @click.stop="onReward(item, 'reward')"> 打赏 </span>
          <i
            class="echeck-ga-icon ec-ga-icon-fabulous_select"
            @click.stop="onLike(item)"
            v-if="item.isMyDianZan"
          />
          <i
            class="echeck-ga-icon ec-ga-icon-fabulous_unselect"
            @click.stop="onLike(item)"
            v-else
          />
          <span
            :class="!item.isMyDianZan ? 'disabled' : ''"
            @click.stop="onLike(item)"
          >
            {{ item.dianZanCount || 0 }}
          </span>
        </div>
        <!--#endregion -->
        <!--#region 待审核 -->
        <template v-else-if="item.state === ADVICE_STATUS.REVIEW.CD">
          <nut-button
            type="primary"
            size="mini"
            plain
            @click.stop="onAudit(item, false)"
          >
            拒绝
          </nut-button>
          <nut-button
            type="primary"
            size="mini"
            class="button"
            @click.stop="onAudit(item, true)"
          >
            通过
          </nut-button>
        </template>
        <!--#endregion -->
      </div>
      <!--#endregion -->
    </div>
    <div class="list-more" v-if="hasMore && loading">加载中...</div>
    <!--    <div class="list-more list-more-bottom" v-else>~ 已经到底啦 ~</div>-->
    <!-- 提建议 -->
    <div class="list-bottom">
      <div class="list-bottom-text">
        为企业成长贡献力量，赢取{{ userStore.config.moneyName }}
      </div>
      <div class="list-bottom-light" @click="navigateTo">我要提建议</div>
    </div>
  </div>
  <SquareItemModal v-model:visible="visible" :data="dialogData" />
  <RewardModal
    v-model:visible="rewardVisible"
    :type="rewardType"
    :data="rewardData"
    @onConfirm="onRewardConfirm"
  />
</template>

<style lang="scss" scoped>
.square-list {
  padding-bottom: 20px;
  background: $color-white;
  box-shadow: 0px 4px 4px 0px $shadow-color;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;

  .list-item {
    padding: 10px 12px 18px 12px;
    border-bottom: 2px solid rgba(#c0caea, 0.2);
    position: relative;
    &-head {
      @include flex-center();
      margin-bottom: 12px;
      .head {
        &-name {
          font-weight: bold;
        }
        &-right {
          @include flex-center(flex-start);
          font-size: $font-small;
          &-text {
            color: $sub-color;
            margin-left: 4px;
            margin-right: 8px;
          }
          &-light {
            color: $color-primary;
            font-weight: bold;
          }
        }
      }
      .fail-icon {
        @include background("@/assets/images/seal-refuse@2x.png", cover);
        width: 60px;
        height: 60px;
        position: absolute;
        top: 35%;
        right: 6%;
      }
    }
    &-content {
      color: $sub-color;
      font-size: $font-small;
      @include multi-hidden();
    }
    &-bottom {
      color: $text-color;
      font-size: $font-small;
      @include flex-center(flex-end);
      margin-top: 12px;
      .echeck-ga-icon {
        margin: 0 4px 0 24px;
      }
      .button {
        margin-left: 8px;
      }
    }
    &-bottom-btn {
      color: $color-primary;

      .disabled {
        color: #acb2ba;
      }
    }
  }

  .list-bottom {
    font-size: $font-small;
    text-align: center;
    &-text {
      color: $text-color;
      padding-top: 20px;
      padding-bottom: 6px;
    }
    &-light {
      color: $color-primary;
    }
  }
  .list-more {
    text-align: center;
    color: #acb2ba;
    padding: 10px 0;
    border-bottom: 2px solid rgba(192, 202, 234, 0.2);
  }
}
.multi-hidden {
  @include multi-hidden(2);
}
</style>
