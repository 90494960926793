import { createApp } from "vue";
import { createPinia } from "pinia";
import {
  Toast,
  Icon,
  OverLay,
  InfiniteLoading,
  Progress,
  Button,
  Dialog,
  Popup,
  Checkbox,
  Radio,
  Switch,
  RadioGroup,
  Swipe,
  Input,
  InputNumber,
  Skeleton,
  Picker,
  Swiper,
  SwiperItem,
} from "@nutui/nutui";

import App from "./App.vue";
import router from "./router";
import { useGlobalStore } from "./stores/global";

import { authInit } from "@/config";

import "@/assets/icons/iconfont.css";
import "./styles/_reboot.scss";
import "./styles/_common.scss";

authInit();

const app = createApp(App);
const pinia = createPinia();

app.use(Dialog).use(Popup);
app.use(Toast);
app.use(Icon);
app.use(OverLay);
app.use(InfiniteLoading);
app.use(Progress);
app.use(Button);
app.use(Checkbox);
app.use(Radio);
app.use(Switch);
app.use(RadioGroup);
app.use(Swipe);
app.use(Input);
app.use(InputNumber);
app.use(Skeleton);
app.use(Picker);
app.use(Swiper);
app.use(SwiperItem);

app.use(pinia);
app.use(router);

// 触发异步全局 store
const globalStore = useGlobalStore();
globalStore.init();

app.mount("#app");
