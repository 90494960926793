import ContentView from "@/views/content/Index.vue";
import HomeView from "@/views/home/Index.vue";

export const content = {
  path: "/",
  component: ContentView,
  children: [
    {
      path: "",
      name: "Home",
      component: HomeView,
    },
    {
      path: "mall",
      name: "Mall",
      component: () => import("../views/mall/Index.vue"),
      meta: {
        title: "商城",
      },
    },
    {
      path: "mine",
      component: () => import("../views/mine/Index.vue"),
      children: [
        {
          path: "",
          name: "Mine",
          component: () => import("../views/mine/Mine.vue"),
        },
        {
          path: "setting",
          name: "MineSetting",
          component: () => import("../views/mine/Setting.vue"),
          meta: {
            title: "设置",
          },
        },
        {
          path: "password/login",
          name: "MinePasswordLogin",
          component: () => import("../views/mine/Password.vue"),
          meta: {
            title: "修改登录密码",
          },
        },
        {
          path: "password/payment",
          name: "MinePasswordPayment",
          component: () => import("../views/mine/Password.vue"),
          meta: {
            title: "修改支付密码",
          },
        },
        {
          path: "phone",
          name: "MinePhone",
          component: () => import("../views/mine/phone/Current.vue"),
          meta: {
            title: "更换手机号码",
          },
        },
        {
          path: "phone/new",
          name: "MinePhoneNew",
          component: () => import("../views/mine/phone/New.vue"),
          meta: {
            title: "更换手机号码",
          },
        },
        {
          path: "address",
          name: "MineAddress",
          component: () => import("../views/mine/address/Index.vue"),
          meta: {
            title: "地址管理",
          },
        },
        {
          path: "address/add",
          name: "MineAddressAdd",
          component: () => import("../views/mine/address/Detail.vue"),
          meta: {
            title: "新增收货地址",
          },
        },
        {
          path: "address/:id",
          name: "MineAddressDetail",
          component: () => import("../views/mine/address/Detail.vue"),
          meta: {
            title: "修改收货地址",
          },
        },
        {
          path: "order/list/:state?",
          name: "MineOrderList",
          component: () => import("../views/mine/order/Index.vue"),
          meta: {
            title: "我的订单",
          },
        },
        {
          path: "order/detail/:orderId",
          name: "MineOrderDetail",
          component: () => import("../views/mine/order/DetailPage.vue"),
          meta: {
            tabbar: false,
            navbarFixed: true,
            navbarStyle: {
              background: "rgba(255, 255, 255, 0)",
            },
            title: "订单详情",
          },
        },
        {
          path: "order/after-sales/list",
          name: "MineOrderAfterSales",
          component: () => import("../views/mine/order/AfterSales.vue"),
          meta: {
            title: "售后",
          },
        },
        {
          path: "points/list",
          name: "MinePointsList",
          component: () => import("../views/mine/points/Index.vue"),
          meta: {
            title: "动账记录",
          },
        },
        {
          path: "advice/list",
          name: "MineAdviceList",
          component: () => import("../views/mine/Advice/Index.vue"),
          meta: {
            title: "我的建议",
          },
        },
      ],
    },
    {
      path: "square",
      name: "Square",
      component: () => import("../views/square/Index.vue"),
      meta: {
        title: "企业文化广场",
      },
    },
  ],
};
export const others = [
  {
    path: "/demo",
    name: "Demo",
    component: () => import("../views/demo/Index.vue"),
    meta: {
      title: "Demo",
    },
  },
  {
    path: "/advice/add",
    name: "AdviceAdd",
    component: () => import("../views/advice/Add.vue"),
    meta: {
      title: "发布建议",
      navbarStyle: {
        background: "#fff",
      },
    },
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/search/Index.vue"),
    meta: {
      title: "搜索",
      navbarStyle: {
        background: "#fff",
      },
    },
  },
  {
    path: "/search/result",
    name: "SearchResult",
    component: () => import("../views/search/Result.vue"),
    meta: {
      title: "搜索结果",
      navbarStyle: {
        background: "#fff",
      },
    },
  },
  {
    path: "/goods/detail/:productNo",
    name: "GoodsDetail",
    component: () => import("../views/mall/GoodsDetail.vue"),
    meta: {
      title: "商品详情",
    },
  },
  {
    path: "/cart",
    name: "Cart",
    component: () => import("../views/mall/cart/Index.vue"),
    meta: {
      title: "购物车",
    },
  },
  {
    path: "/order",
    name: "Order",
    component: () => import("../views/mall/order/Index.vue"),
    meta: {
      title: "提交订单",
    },
  },
  {
    path: "/pay",
    name: "Pay",
    component: () => import("../views/mall/pay/Index.vue"),
    meta: {
      title: "支付",
    },
  },
  {
    path: "/pay/result/:type",
    name: "PayResult",
    component: () => import("../views/mall/pay/Result.vue"),
    meta: {
      title: "支付",
      navbarStyle: {
        background: "#fff",
      },
    },
  },
];
