import { defineStore } from "pinia";
import { getConfByAlias } from "@/views/api";
import { getUrlParams } from "@/utils";
import { FETCH_CODE } from "@/config";

export const useGlobalStore = defineStore("globalStore", {
  state: () => {
    return {
      companyId: "",
      logo: "",
      name: "",
      moneyName: "",
    };
  },
  actions: {
    async init() {
      // 获取平台配置
      const confRes = await getConfByAlias({
        alias: getUrlParams("c"),
      });
      if (confRes.code !== FETCH_CODE.SUCCESS) return;
      for (const key in confRes.data) {
        this[key] = confRes.data[key];
      }
    },
  },
});
