import PaySuccess from "@/assets/images/successfulico@2x.png";
import PayFailed from "@/assets/images/failureico@2x.png";

export const PAY_TYPE = [
  {
    id: 221,
    dictType: "pay_type",
    dictCode: "only_points",
    dictName: "福币支付",
    dictIcon: "",
    sort: 1,
    remark: "",
    state: true,
  },
  {
    id: 222,
    dictType: "pay_type",
    dictCode: "only_price",
    dictName: "现金支付",
    dictIcon: "",
    sort: 2,
    remark: "",
    state: true,
  },
  {
    id: 223,
    dictType: "pay_type",
    dictCode: "points_price",
    dictName: "福币+现金",
    dictIcon: "",
    sort: 3,
    remark: "",
    state: true,
  },
  {
    id: 224,
    dictType: "pay_type",
    dictCode: "growth_points",
    dictName: "成长积分支付",
    dictIcon: "",
    sort: 4,
    remark: "",
    state: true,
  },
];

export const PAYMENT_TYPE = {
  WX_JS: {
    KEY: Symbol("wx_js"),
    CD: 1,
    NAME: "微信JSAPI",
  },
  WX_NATIVE: {
    KEY: Symbol("wx_native"),
    CD: 2,
    NAME: "微信NATIVE",
  },
  WX_APP: {
    KEY: Symbol("wx_app"),
    CD: 4,
    NAME: "微信APP",
  },
  WX_H5: {
    KEY: Symbol("wx_h5"),
    CD: 3,
    NAME: "微信H5",
  },
  ALI_PAY: {
    KEY: Symbol("ali_pay"),
    CD: 6,
    NAME: "支付宝",
  },
  ALI_PAY_SCALE: {
    KEY: Symbol("ali_pay_scale"),
    CD: 5,
    NAME: "支付宝扫码",
  },
};

export const PAY_WAY = {
  COIN: {
    KEY: Symbol("coin"),
    CD: PAY_TYPE[0].dictCode,
    NAME: "福币支付",
    ENABLE: true,
  },
  SCORE: {
    KEY: Symbol("score"),
    CD: PAY_TYPE[3].dictCode,
    NAME: "HR成长积分",
    ENABLE: false,
  },
  CASH: {
    KEY: Symbol("cash"),
    CD: PAY_TYPE[1].dictCode,
    NAME: "现金支付",
    ENABLE: true,
  },
};

export const PAY_WAY_CASH = {
  WE_CHAT: {
    KEY: Symbol("weChat"),
    CD: PAYMENT_TYPE.WX_JS.CD,
    NAME: "微信",
  },
  ALI_PAY: {
    KEY: Symbol("aliPay"),
    CD: PAYMENT_TYPE.ALI_PAY.CD,
    NAME: "支付宝",
  },
};

export const PAY_RESULT = {
  success: {
    cd: "1",
    name: "支付成功",
    icon: PaySuccess,
  },
  failed: {
    cd: "0",
    name: "支付失败",
    icon: PayFailed,
  },
};

// 支付状态 0=待付款 1=支付成功 2=已关闭 3=支付失败
export const TRADE_STATE = [
  {
    id: "trade_state_0",
    dictType: "trade_state",
    dictCode: 0,
    dictName: "待付款",
    dictIcon: "",
    sort: 1,
    remark: "",
    state: true,
  },
  {
    id: "trade_state_1",
    dictType: "trade_state",
    dictCode: 1,
    dictName: "支付成功",
    dictIcon: "",
    sort: 2,
    remark: "",
    state: true,
  },
  {
    id: "trade_state_2",
    dictType: "trade_state",
    dictCode: 2,
    dictName: "已关闭",
    dictIcon: "",
    sort: 3,
    remark: "",
    state: true,
  },
  {
    id: "trade_state_3",
    dictType: "trade_state",
    dictCode: 3,
    dictName: "支付失败",
    dictIcon: "",
    sort: 4,
    remark: "",
    state: true,
  },
];

// 金额换算比率 后端存 *100 前端显示*0.01
export const PRICE_RATE_BACK = 100;
export const PRICE_RATE_FRONT = 0.01;
export const PRICE_DECIMALS = 2;
