import { Toast, Dialog } from "@nutui/nutui";
import { FETCH_CODE } from "@/config";

/**
 * 展示消息
 * @param {Object} options
 * @param {0|1|2|3|4} options.code
 * @param {string} options.msg
 * @param {string=} options.title
 */
export function showToast(options) {
  const duration = 1000;
  return new Promise((resolve) => {
    const { code, msg, title } = options;
    if (code === FETCH_CODE.SUCCESS) {
      Toast.success(msg, {
        duration: duration,
      });
      setTimeout(() => {
        resolve(true);
      }, duration);
      return;
    }
    if (code === FETCH_CODE.WARNING) {
      Toast.warn(msg, {
        duration: duration,
      });
      setTimeout(() => {
        resolve(true);
      }, duration);
      return;
    }
    if (code === FETCH_CODE.ERROR) {
      Toast.fail(msg, {
        duration: duration,
      });
      setTimeout(() => {
        resolve(true);
      }, duration);
      return;
    }
    if (code === FETCH_CODE.ALERT) {
      Dialog({
        title: title || "",
        content: msg,
        noCancelBtn: true,
        popClass: "ec-toast-dialog",
        onOk: () => {
          resolve(true);
        },
      });
      return;
    }
    if (code === FETCH_CODE.CONFIRM) {
      Dialog({
        title: title || "",
        content: msg,
        popClass: "ec-toast-dialog",
        onCancel: () => {
          resolve(false);
        },
        onOk: () => {
          resolve(true);
        },
      });
      return;
    }
    resolve(null);
  });
}
