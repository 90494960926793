<script setup>
import Modal from "./Modal.vue";
import { computed, reactive, ref, watch } from "vue";
import { useUserStore } from "@/stores/user";
import { formatNumber, showToast } from "@/utils";
import { FETCH_CODE, PRICE_DECIMALS } from "@/config";
import { trim } from "lodash";
import BigNumber from "bignumber.js";

const emits = defineEmits(["update:visible", "onConfirm"]);
const userStore = useUserStore();
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
    default: false,
  },
  type: {
    required: true,
    validator(value) {
      return ["reward", "audit", "reject"].includes(value);
    },
  },
  data: {
    required: true,
    type: Object,
  },
});

const form = reactive({
  value: null,
  password: null,
});

const text = new Proxy(
  {
    audit: ["审核通过", "发布人", `奖励${userStore.config.moneyName}`],
    reward: ["打赏", "打赏给", "打赏金额"],
    reject: ["审核拒绝", "发布人", "拒绝原因"],
  },
  {
    get(target, p) {
      if (!target[p]) {
        return Reflect.get(target, "reward");
      }
      return Reflect.get(target, p);
    },
  },
);
const showText = ref(text[props.type]);

const tip = computed(() => {
  const val = +form.value || 0;
  return BigNumber(val)
    .times(userStore.serviceCharge)
    .div(100)
    .toFixed(PRICE_DECIMALS);
});
const serviceCharge = computed(() => {
  return userStore.serviceCharge;
});
const visible = ref(props.visible);
watch(props, (_props) => {
  visible.value = _props.visible;
  if (visible.value) {
    showText.value = text[props.type];
    form.value = null;
    form.password = null;
    form.remark = null;
  }
});
watch(userStore.config, (_config) => {
  text.audit[2] = `奖励${_config.moneyName}`;
});

const onClose = () => {
  emits("update:visible", false);
};

const onConfirm = () => {
  const valid = onValid();
  if (!valid) return;
  emits("onConfirm", form);
};

const onValid = () => {
  // 审核拒绝
  if (isReject.value) {
    if (!trim(form.remark)) {
      showToast({ code: FETCH_CODE.WARNING, msg: "请输入拒绝原因" });
      return false;
    }
    return true;
  }

  let typeMsg = "奖励";
  // 打赏
  if (isReward.value) {
    typeMsg = "打赏";
  }
  // 审核通过
  if (!trim(form.value)) {
    showToast({
      code: FETCH_CODE.WARNING,
      msg: `请输入${typeMsg}${userStore.config.moneyName}数量`,
    });
    return false;
  }
  if (form.value > userStore.companyBalance) {
    showToast({
      code: FETCH_CODE.WARNING,
      msg: "您输入的金额超出可用余额",
    });
    return false;
  }
  if (!trim(form.password)) {
    showToast({ code: FETCH_CODE.WARNING, msg: "请输入支付密码" });
    return false;
  }
  return true;
};

const isReward = computed(() => {
  return props.type === "reward";
});
const isReject = computed(() => {
  return props.type === "reject";
});

const rewardInputFormatter = (val) => {
  if (!val) return val;
  return formatNumber(+val, PRICE_DECIMALS);
};
</script>

<template>
  <Modal
    v-model:visible="visible"
    @update:visible="onClose"
    :title="showText[0]"
    @onConfirm="onConfirm"
    :before-confirm-close="() => false"
  >
    <div class="reward-modal-inner">
      <div class="reward-tips" v-if="isReward">
        请注意，打赏需额外支付打赏金额{{ serviceCharge }}%的手续费
      </div>
      <div class="reward-form-item reward-target">
        <label class="reward-form-label"> {{ showText[1] }}： </label>
        <div class="reward-form-control">{{ data.title }}</div>
      </div>
      <template v-if="!isReject">
        <div class="reward-form-item reward-value required">
          <label class="reward-form-label"> {{ showText[2] }}： </label>
          <div class="reward-form-control">
            <nut-input
              type="number"
              class="reward-form-input number"
              :placeholder="`请输入${showText[2]}`"
              autocomplete="off"
              v-model="form.value"
              :formatter="rewardInputFormatter"
              format-trigger="onBlur"
            />
            <div class="reward-form-addon" v-if="isReward">
              + 手续费 {{ tip }}
            </div>
            <div class="reward-form-error">
              <span>可用</span>
              <span class="text-primary" style="margin: 0 5px">{{
                userStore.companyBalance
              }}</span>
              <span>{{ userStore.config.moneyName }}</span>
            </div>
          </div>
        </div>
        <div class="reward-form-item required">
          <label class="reward-form-label"> 支付密码： </label>
          <div class="reward-form-control">
            <input
              type="password"
              class="reward-form-input"
              placeholder="请输入平台的支付密码"
              autocomplete="off"
              v-model="form.password"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="reward-form-item required remark">
          <label class="reward-form-label" style="width: 75px">
            {{ showText[2] }}：
          </label>
          <div class="reward-form-control">
            <textarea
              :rows="5"
              class="reward-form-input"
              placeholder="请输入拒绝原因"
              v-model="form.remark"
            />
          </div>
        </div>
      </template>
    </div>
  </Modal>
</template>

<style scoped lang="scss">
.reward-modal-inner {
  padding: 0 0.1rem;

  .reward-tips {
    font-size: 12px;
    font-weight: 500;
    color: $color-primary;
    margin-bottom: 12px;
  }
  .reward-form-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;

    &.required {
      .reward-form-label::before {
        content: "*";
        display: block;
        color: $color-primary;
        font-size: 16px;
        font-weight: 600;
        position: absolute;
        z-index: 1;
        left: -14px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .remark {
    align-items: flex-start;
    textarea {
      resize: none;
    }
  }
  .reward-form-label {
    width: 75px;
    font-size: 14px;
    font-weight: 500;
    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
    white-space: nowrap;
  }
  .reward-form-control {
    flex: 1;
    width: 0;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  .reward-form-input {
    width: 100%;
    height: 38px;
    background: $color-white;
    border-radius: 4px;
    border: 1px solid #757e8c;
    font-size: 12px;
    font-weight: 400;
    color: #757e8c;
    padding: 0 8px;
    outline: none;

    &:focus {
      border-color: $color-primary;
    }

    &.number {
      padding: 0;
      border: 0;
      border-radius: 0;

      :deep(input) {
        height: 38px;
        padding: 0 8px;
        border-radius: 4px;
        border: 1px solid #757e8c;

        &:focus {
          border-color: $color-primary;
        }
      }
    }
  }
  .reward-form-addon {
    font-size: 12px;
    font-weight: 500;
    color: $color-primary;
    margin-left: 7px;
    @include single-hidden;
  }
  .reward-form-error {
    display: block;
    width: 100%;
    position: absolute;
    z-index: 1;
    font-size: 10px;
    font-weight: 400;
    bottom: -20px;
    left: 0;
    @include single-hidden;
  }

  .reward-form-item.reward-value {
    .reward-form-input {
      width: 120px;
    }
  }

  .reward-form-item.reward-target {
    margin-bottom: 20px;
  }
}
</style>
