import { defineStore } from "pinia";

// 基础数据store
export const useBaseStore = defineStore("baseStore", () => {
  // ref()s become state properties
  // computed()s become getters
  // function()s become actions

  // 初始化
  function init() {}

  return {
    init,
  };
});
