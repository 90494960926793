<script setup>
import Modal from "./Modal.vue";
import { computed, ref, watch } from "vue";
import { ADVICE_STATUS } from "@/config";
import { ImagePreview } from "@nutui/nutui";

const emits = defineEmits(["update:visible"]);

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
    default: false,
  },
  data: {
    type: Object,
    required: true,
  },
});

const visible = ref(props.visible);
watch(props, (_props) => {
  visible.value = _props.visible;
});

const onClose = () => {
  emits("update:visible", false);
};

const onPreview = (src) => {
  ImagePreview({
    show: true,
    images: [{ src }],
  });
};

const title = computed(() => {
  return props.data.status === ADVICE_STATUS.REVIEW.CD ? "待审核" : "";
});
</script>

<template>
  <Modal
    v-model:visible="visible"
    @update:visible="onClose"
    :title="title"
    :show-confirm="false"
    cancel-text="返回"
    @onCancel="onClose"
  >
    <div class="square-dialog">
      <div class="square-dialog-top">
        <span class="square-dialog-top-name">{{ data.title }}</span>
        <span class="square-dialog-top-date">{{ data.createTime }}</span>
      </div>
      <div class="square-dialog-content">
        <span>
          {{ data.remark }}
        </span>
        <div
          class="square-dialog-icon"
          :class="`square-dialog-icon-${props.data.state}`"
        ></div>
      </div>
      <div
        class="square-dialog-picture"
        v-if="data.pictureList && data.pictureList.length"
      >
        <img
          v-for="i in data.pictureList"
          :key="i"
          :src="i"
          alt=""
          @click="onPreview(i)"
        />
      </div>
      <div
        class="square-dialog-reject-reason"
        v-if="
          props.data.state === ADVICE_STATUS.INVALID.CD && data.rejectedReason
        "
      >
        <strong>未通过原因：</strong>
        <span>{{ data.rejectedReason }}</span>
      </div>
    </div>
  </Modal>
</template>

<style scoped lang="scss">
.square-dialog {
  &-top {
    @include flex-center();
    margin-bottom: 16px;

    &-name {
      font-weight: bold;
      color: $font-color;
      font-size: $font-normal;
    }

    &-date {
      font-size: $font-small;
      color: $sub-color;
    }
  }
  &-content {
    font-size: $font-small;
    color: $font-color;
    position: relative;
    word-break: break-all;
  }
  &-picture {
    margin-top: 0.2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.1rem;
    box-sizing: border-box;
    img {
      width: 90px;
      height: 90px;
      object-fit: cover;
      flex-shrink: 0;
    }
  }
  &-close {
    position: absolute;
    top: 16px;
    right: 20px;
  }

  &-icon {
    width: 86px;
    height: 72px;
    position: absolute;
    top: -40px;
    right: 0;
    z-index: -1;
  }

  &-icon-passed {
    @include background("@/assets/images/pass@2x.png", cover);
  }
  &-icon-un_approved {
  }
  &-icon-rejected {
    @include background("@/assets/images/seal-refuse@2x.png", cover);
  }
  &-reject-reason {
    margin-top: 10px;
    font-size: $font-small;
    color: $color-danger;

    strong {
      font-weight: 600;
      color: $font-color;
    }
  }
}
</style>
