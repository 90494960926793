<script setup>
import { onMounted, ref, watch } from "vue";
import { useTabStore } from "@/stores/tabbar.js";
import { useRouter, useRoute } from "vue-router";
const { tabList, active, setActive } = useTabStore();
const router = useRouter();
const route = useRoute();

const activeTab = ref(active);

const navigateTo = (key, path) => {
  router.push({ path });
};
const changActiveTab = (currentRoute) => {
  const curr = tabList.find((it) => {
    if (currentRoute.fullPath === it.path) return it;
    if (it.children && it.children.includes(currentRoute.fullPath)) return it;
    return null;
  });
  if (curr) {
    activeTab.value = curr.key;
    setActive(activeTab.value);
  }
};

watch(
  () => route,
  (newVal) => {
    changActiveTab(newVal);
  },
  //深度监听
  { deep: true },
);

onMounted(() => {
  changActiveTab(route);
});
</script>

<template>
  <div class="ec-tabbar">
    <div
      class="ec-tabbar-item"
      v-for="i in tabList"
      :key="i.key"
      @click="navigateTo(i.key, i.path)"
    >
      <i
        :class="[
          `echeck-ga-icon ${i.icon}-${activeTab === i.key ? 'dark' : 'light'}`,
        ]"
      ></i>
      <div class="ec-tabbar-item-shadow" v-if="activeTab === i.key"></div>
      <div
        :class="[
          activeTab === i.key
            ? 'ec-tabbar-item-active'
            : 'ec-tabbar-item-title',
        ]"
      >
        {{ i.tabName }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ec-tabbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $color-white;
  padding: 8px 20px;
  @include flex-center();
  &-item {
    position: relative;
    text-align: center;
    font-size: $font-small;
    i {
      font-size: 20px;
    }
    &-shadow {
      position: absolute;
      width: 20px;
      height: 20px;
      background: linear-gradient(
        43deg,
        #e85729 0%,
        rgba(232, 87, 41, 0.07) 100%
      );
      border-radius: 50%;
      right: -6px;
      top: 0;
    }
    &-title {
      color: $color-disabled;
    }
    &-active {
      .ec-tabbar-item-title {
        color: $color-primary;
      }
    }
  }
}
</style>
