<script setup>
import { useSquareStore } from "@/stores/square";
import { computed } from "vue";

const squareStore = useSquareStore();

const rankList = computed(() => squareStore.rankList);
</script>

<template>
  <div class="square-rank">
    <div class="square-rank-item" v-for="item in rankList" :key="item.userId">
      <div
        class="square-rank-item-avatar"
        :style="{ backgroundImage: `url(${item.headImg})` }"
      >
        <div
          class="square-rank-item-tag"
          :style="{ backgroundColor: item.color }"
        >
          {{ item.rankingTypeName }}
        </div>
        <!-- 王冠图标 -->
        <div class="echeck-ga-icon ec-ga-icon-crown" v-if="!item.headImg" />
      </div>
      <div class="square-rank-item-name">{{ item.userName || "虚位以待" }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.square-rank {
  @include flex-center();
  gap: 10px;
  margin-bottom: 12px;
  &-item {
    width: 108px;
    background: $color-white;
    box-shadow: 0px 4px 4px 0px $shadow-color;
    border-radius: 6px;
    padding: 12px 19px;
    &-avatar {
      width: 70px;
      height: 70px;
      border: 1px solid #e9ebfb;
      border-radius: 50%;
      position: relative;
      @include background;
    }
    .ec-ga-icon-crown {
      @include position-center(absolute, 15%, 50%);
      font-size: 30px;
    }
    &-name {
      margin-top: 6px;
      font-size: $font-small;
      text-align: center;
    }
    &-tag {
      font-size: $font-small;
      color: $color-white;
      text-align: center;
      padding: 2px 5px;
      border-radius: 10px;
      position: absolute;
      bottom: 0;
      left: 50%;
      white-space: nowrap;
      transform: translate(-50%, 0);
    }
  }
}
</style>
