<script setup>
import { onMounted, ref, watch } from "vue";
import { ModalConf } from "@/config/modal";

const emits = defineEmits(["update:visible", "onCancel", "onConfirm"]);

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
    default: false,
  },
  overlayClose: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
  },
  confirmText: {
    type: String,
    default: "确定",
  },
  cancelText: {
    type: String,
    default: "取消",
  },
  showConfirm: {
    type: Boolean,
    default: true,
  },
  showCancel: {
    type: Boolean,
    default: true,
  },
  beforeConfirmClose: {
    type: Function,
    default: () => Promise.resolve(true),
  },
});

let zIndex = 2000;
onMounted(() => {
  zIndex = ModalConf.open();
});

const visible = ref(props.visible);
watch(props, (_props) => {
  visible.value = _props.visible;
});

const onClose = () => {
  emits("update:visible", false);
};

const onCancel = async () => {
  await emits("onCancel");
  onClose();
};

const onConfirm = async () => {
  await emits("onConfirm");
  const closeContinue = await props.beforeConfirmClose();
  if (closeContinue === false) return;
  onClose();
};
</script>

<template>
  <nut-popup
    pop-class="ec-modal"
    overlay-class="ec-modal-overlay"
    v-model:visible="visible"
    :z-index="zIndex"
    position="center"
    :close-on-click-overlay="props.overlayClose"
    @open="() => {}"
    @close="onClose"
  >
    <div class="ec-modal-header">
      <h3 class="ec-modal-title">{{ props.title }}</h3>
      <i
        class="ec-modal-close echeck-ga-icon ec-ga-icon-close"
        @click="onClose"
      ></i>
    </div>
    <div class="ec-modal-content">
      <slot></slot>
    </div>
    <div class="ec-modal-footer">
      <nut-button
        class="ec-modal-btn"
        type="primary"
        size="mini"
        plain
        @click="onCancel"
        v-if="props.showCancel"
      >
        {{ props.cancelText }}
      </nut-button>
      <nut-button
        class="ec-modal-btn"
        type="primary"
        size="mini"
        @click="onConfirm"
        v-if="props.showConfirm"
      >
        {{ props.confirmText }}
      </nut-button>
    </div>
  </nut-popup>
</template>

<style lang="scss">
.ec-modal {
  width: calc(100vw - 28px);
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  padding: 11px 18px 44px 18px;

  .ec-modal-header {
    width: 100%;
    position: relative;
  }
  .ec-modal-title {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    min-height: 28px;
  }
  .ec-modal-close {
    font-size: 14px;
    display: block;
    width: 20px;
    height: 20px;
    text-align: right;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .ec-modal-content {
    margin-top: 19px;
  }
  .ec-modal-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    margin-top: 30px;
  }
  .ec-modal-btn {
    flex-grow: 0;
    flex-shrink: 0;
    width: 138px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 4px;

    .nut-button__warp {
      transform: translateX(2px);
    }
  }
}
</style>
