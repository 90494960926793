import { getUrlParams, isWechat, parseGetUrl } from "@/utils/tools";
import { WECHAT } from "@/config";

export class Wechat {
  // 微信网页授权接口
  static goOAuth2(redirect = "/common/loading") {
    if (!isWechat()) {
      console.log("非微信环境，正常登录");
      window.location.replace(
        parseGetUrl("/account", {
          c: getUrlParams("c"),
        }),
      );
      return;
    }
    let url = WECHAT.O_AUTH_HOST;
    url = parseGetUrl(url, {
      appid: WECHAT.APPID,
      redirect_uri: encodeURIComponent(`${window.location.origin}${redirect}`),
      response_type: "code",
      scope: "snsapi_base",
      // scope: "snsapi_userinfo",
      state: "entry",
    });
    window.location.replace(`${url}#wechat_redirect`);
  }

  /**
   * JSSDK微信支付
   * @param options
   * @param {number} options.appId 公众号ID，由商户传入
   * @param {number} options.timeStamp 时间戳，自1970年以来的秒数
   * @param {string} options.nonceStr 支付签名随机串，不长于 32 位
   * @param {string} options.package 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
   * @param {string} options.signType 微信签名方式
   * @param {string} options.paySign 支付签名
   * @returns {Promise<*>}
   */
  static pay(options) {
    return new Promise((resolve) => {
      const onBridgeReady = () => {
        window.WeixinJSBridge.invoke("getBrandWCPayRequest", options, (res) => {
          if (res.err_msg === WECHAT.PAY_RES_SUCCESS) {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            // res.err_msg 将在用户支付成功后返回 ok，但并不保证它绝对可靠。
          }
          resolve(res);
        });
      };
      if (typeof window.WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false,
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        onBridgeReady();
      }
    });
  }
}
