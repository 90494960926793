import { createRouter, createWebHistory } from "vue-router";
import { account } from "@/router/account";
import { content, others } from "@/router/content";
import { common } from "@/router/common";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [content, account, ...common, ...others],
});

export const ROUTE_WHITE_LIST = [
  "^/account",
  "^/common/loading",
  "^/common/agreement",
  "^/common/privacy",
];

router.beforeEach((to, from, next) => {
  // 白名单
  // for (const white of ROUTE_WHITE_LIST) {
  //   if (new RegExp(white).test(to.path)) {
  //     next();
  //     return;
  //   }
  // }
  // // 校验登录
  // if (!AUTH.TOKEN) {
  //   // 触发微信登录
  //   Wechat.goOAuth2();
  //   return;
  // }
  next();
});

router.afterEach((to) => {
  for (let i = document.body.classList.length - 1; i >= 0; i--) {
    const clsNa = document.body.classList[i];
    if (/^route/.test(clsNa)) {
      document.body.classList.remove(clsNa);
    }
  }
  document.body.classList.add(`route-${to.name}`);
});

export default router;
