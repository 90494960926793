<script setup>
import { onMounted, ref } from "vue";
import GoodsCard from "@/components/GoodsCard.vue";
import SquareRank from "@/components/SquareRank.vue";
import SquareList from "@/components/SquareList.vue";
import ECbanner from "@/components/Banner.vue";
import { useRouter } from "vue-router";
import { getBanner } from "@/views/home/api";
import { ADVICE_STATUS, FETCH_CODE } from "@/config";
import { pointsShowFormatter, productItemFormatter } from "@/utils";
import { productListPage } from "@/views/mall/api";
import { getUserAdvice } from "@/views/square/api";
import { useUserStore } from "@/stores/user";

const router = useRouter();
const userStore = useUserStore();

const bannerTop = ref([]);
const recommendList = ref([]);
const adviceList = ref([]);

const navigateToSearch = () => {
  router.push({ name: "Search" });
};

// 获取顶部banner
const getBannerTop = async () => {
  const res = await getBanner({ bannerType: "shopping_home" });
  if (res.code !== FETCH_CODE.SUCCESS) return;
  if (!res.data || !res.data.length) return;
  // 显示激活的且排序靠前的
  bannerTop.value = res.data
    .filter((it) => it.state)
    .sort((a, b) => a.sort - b.sort);
};

// 获取推荐商品
const getRecommendGoods = async () => {
  const res = await productListPage({
    pageSize: 4,
    pageNum: 1,
    isRecommend: true,
    upAndDownShelfState: true,
  });
  if (res.code !== FETCH_CODE.SUCCESS) {
    recommendList.value = [];
    return;
  }
  if (!res.data || !res.data.records) {
    recommendList.value = [];
    return;
  }
  const _recommendList = [];
  res.data.records
    .sort((a, b) => a.sort - b.sort)
    .forEach((it) => {
      _recommendList.push({
        ...it,
        ...productItemFormatter(it),
      });
    });
  recommendList.value = _recommendList;
};

// 获取建议列表
const getAdviceList = async () => {
  const res = await getUserAdvice({
    pageSize: 5,
    pageNum: 1,
    state: ADVICE_STATUS.PASSED.CD,
  });
  if (res.code !== FETCH_CODE.SUCCESS) {
    return;
  }
  const _adviceList = res.data.records || [];
  _adviceList.forEach((it) => {
    it.totalPoints = it.totalPoints || 0;
    it.showTotalPoints = pointsShowFormatter(it.totalPoints);
  });
  adviceList.value = res.data.records;
};

onMounted(() => {
  getBannerTop();
  getRecommendGoods();
  getAdviceList();
});
</script>

<template>
  <div class="ec-home ec-bottom ec-background">
    <!-- 阴影部分 -->
    <!-- <div class="ec-shadow">
      <div class="ec-shadow-inner"></div>
    </div> -->
    <!-- 头部标题 -->
    <div class="ec-logo ec-container" id="ecHomeLogo">
      <img
        class="ec-logo-img"
        :src="userStore.config.logo"
        :alt="userStore.config.name"
      />
    </div>
    <div class="ec-home-header ec-container">
      <div class="ec-header-title">{{ userStore.config.name }}</div>
      <div class="ec-header-icon" @click="navigateToSearch"></div>
    </div>
    <!-- banner -->
    <div class="ec-container">
      <ECbanner :list="bannerTop"></ECbanner>
    </div>
    <!-- body -->
    <div class="ec-home-body ec-container">
      <!-- 好物推荐 -->
      <div class="ec-home-body-title" v-if="recommendList.length">好物推荐</div>
      <div class="body-recommend">
        <GoodsCard v-for="i in recommendList" :key="i.key" :item="i" />
      </div>
      <!-- 企业文化广场 -->
      <template v-if="userStore.config.tool">
        <div class="ec-home-body-title">企业文化广场</div>
        <SquareRank />
        <SquareList :state="ADVICE_STATUS.PASSED.CD" :list="adviceList" />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ec-home {
  .ec-logo {
    height: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &-img {
      display: block;
      height: 24px;
    }
  }
  &-header {
    height: 50px;
    @include flex-center();
    position: sticky;
    top: 0;
    z-index: 1;

    .ec-header-title {
      color: $color-text;
      font-size: 24px;
      font-weight: bold;
    }
    .ec-header-icon {
      width: 24px;
      height: 24px;
      @include background("@/assets/images/search@2x.png");
    }
  }
  &-body {
    &-title {
      color: $color-text;
      font-size: 18px;
      font-weight: bold;
      margin: 20px auto;
    }
    .body-recommend {
      @include flex-center(flex-start);
      flex-wrap: wrap;
      gap: 10px;
    }
  }
  .ec-shadow {
    width: calc(100vw - 107px);
    height: calc(100vw - 107px);
    border: 1px solid red;
    position: absolute;
    left: -$gutter15;
    &-inner {
      width: 270px;
      height: 260px;
      border: 1px solid rebeccapurple;
      position: absolute;
      transform: rotate(-45deg);
      background: $color-primary;
      opacity: 0.08;
      transform-origin: right top;
    }
  }
}
</style>
