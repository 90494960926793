<script setup>
import { RouterView, useRoute, useRouter } from "vue-router";
import { onMounted } from "vue";
import { useUserStore } from "@/stores/user";
import { useBaseStore } from "@/stores/base";
import { useSquareStore } from "@/stores/square";

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const baseStore = useBaseStore();
const squareStore = useSquareStore();

const onBack = () => {
  router.back();
};

onMounted(() => {
  userStore.getInfo();
  baseStore.init();
  squareStore.getRankList();
});
</script>

<template>
  <div class="ec-main-app" id="app">
    <div
      :class="['ec-container', 'router', route.meta.navbarFixed ? 'fixed' : '']"
      :style="route.meta.navbarStyle || {}"
      v-if="route.meta.title"
    >
      <span
        class="echeck-ga-icon ec-ga-icon-arrow-left router-icon"
        :style="route.meta.backStyle || {}"
        @click="onBack"
      />
      <span class="router-title">{{ route.meta.title }}</span>
    </div>
    <RouterView></RouterView>
  </div>
</template>

<style lang="scss">
.ec-background {
  background-image: url("@/assets/images/background@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 340px;
  background-attachment: fixed;
}
.ec-main-app {
  .router {
    width: 100%;
    height: 44px;
    position: relative;
    line-height: 44px;
    background-color: #fbe0d1;
    font-size: 17px;
    color: $color-black;
    @include flex-center(flex-start);
    align-items: stretch;

    &.fixed {
      position: fixed;
    }

    &-icon {
      font-size: 24px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      position: absolute;
      z-index: 1;
      left: $gutter15;
      top: 0;
    }

    &-title {
      text-align: center;
      flex: 1;
    }
  }
}
</style>
